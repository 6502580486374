/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import TopNavPortal from '../../../components/layout/top-menu/TopNavPortal';
import PermissionUserDetailItem from '../../../components/permissions/PermissionUserDetailItem';
import Checkbox from '../../../components/shared/form-control/Checkbox';
import ConfirmationModal from '../../../components/shared/modal/variants/ConfirmationModal';
import PageLoader from '../../../components/shared/page-loader/PageLoader';
import StatusTag, { StatusVariant } from '../../../components/shared/tags/StatusTag';
import { Heading, HeadingSize } from '../../../components/shared/text/Heading';
import { useToasts, ToastType } from '../../../contexts/ToastContext';
import User from '../../../models/User';
import AdminService from '../../../services/AdminService';
import UserService from '../../../services/UserService';
import { getHighestRole, Roles, RolesHierarchy } from '../../../models/Role';
import Button, { ButtonType } from '../../../components/shared/form-control/Button';
import { Input } from '../../../components/shared/form-control/Input';
import { Field, FieldProps, Formik, FormikHelpers, FormikState } from 'formik';
import { UserProfile } from '../../../models/AuthModels';
import useSchema from '../../../schema';
import DropdownSelect from '../../../components/shared/form-control/DropdownSelect';
import { supportedLanguages } from '../../../types/Languages';
import ContextMenu, { ContextMenuItem } from '../../../components/shared/ContextMenu';
import AuthService from '../../../services/AuthService';
import ClientService from '../../../services/ClientService';
import StaticBreadCrumb from '../../../components/shared/breadcumb/StaticBreadCrumb';
import { useSetRecoilState } from 'recoil';
import { topNavHeadingAtom } from '../../../recoil/atoms/Workspace';
import { Option } from '../../../components/Option';
import { ModalContext } from '../../../contexts/ModalContext';
import { Client } from '../../../models/Client';

const UserDetail: FC = () => {
  const { userId } = useParams<{ userId: string }>();
  const [user, setUser] = useState<User | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation(['organisation', 'common', 'user-settings']);
  const [modal, setModal] = useState<JSX.Element | null>(null);
  const [hasArchitectRole, setHasArchitectRole] = useState(false);
  const toasts = useToasts();
  const [isEditing, setIsEditing] = useState(false);
  const resetFormRef = useRef<(nextState?: Partial<FormikState<UserProfile>> | undefined) => void | null>();
  const [initialValues, setInitialValues] = useState<UserProfile>({} as UserProfile);
  const setTopNavheading = useSetRecoilState(topNavHeadingAtom);
  const schemas = useSchema();
  const languageOptions = useMemo(() => {
    return supportedLanguages.map((lang) => ({
      id: lang.id,
      value: lang.value,
      text: t(`common:languages.${lang.id}`),
    }));
  }, [t]);
  const [changedRoles, setChangedRoles] = useState<Record<string, (typeof Roles)[keyof typeof Roles]>>({});
  const [accounts, setAccounts] = useState<Client[]>([]);

  useEffect(() => {
    AdminService.getAccounts({ pageNumber: 1, pageSize: 999 }).then((res) => {
      setAccounts(res.data);
    });
  }, []);

  const getUser = useCallback(() => {
    setIsLoading(true);
    AdminService.getUser(userId as string)
      .then((res) => {
        setUser(res.data);
        setHasArchitectRole(res.data.isArchitect);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [userId]);

  useEffect(() => {
    getUser();
  }, [getUser]);

  useEffect(() => {
    setInitialValues({
      emailAddress: user?.email || '',
      firstName: user?.firstName || '',
      lastName: user?.lastName || '',
      jobTitle: user?.jobTitle || '',
      languageCode: user?.language || '',
      phoneNumber: user?.phoneNumber || '',
    });
  }, [user?.email, user?.firstName, user?.jobTitle, user?.language, user?.lastName, user?.phoneNumber]);

  const showArchitectModal = () => {
    setModal(
      <ModalContext.Provider value={{ open: true }}>
        <ConfirmationModal
          title={t(`common:permissions.user.modals.builder-access.${!hasArchitectRole ? 'grant' : 'revoke'}.title`)}
          description={t(`common:permissions.user.modals.builder-access.${!hasArchitectRole ? 'grant' : 'revoke'}.description`, {
            user: user?.firstName || t('common:permissions.user.modals.this-user'),
          })}
          confirmText={t(`common:permissions.user.modals.builder-access.${!hasArchitectRole ? 'grant' : 'revoke'}.title`)}
          onCancel={() => setModal(null)}
          onConfirm={async () => {
            if (user?.id)
              await UserService.updateArchitectPermission(user?.id, !hasArchitectRole)
                .then(() => {
                  setHasArchitectRole(!hasArchitectRole);
                })
                .catch((err) => {
                  if (err?.meta?.message)
                    toasts.addToast({
                      type: ToastType.ERROR,
                      title: 'Error',
                      description: err.meta.message,
                      expiresInMs: 2000,
                    });
                  console.error(err);
                })
                .finally(() => setModal(null));
          }}
        />
      </ModalContext.Provider>,
    );
  };

  const saveUser = (values: UserProfile, { setSubmitting }: FormikHelpers<UserProfile>) => {
    if (!user?.id) {
      return;
    }

    setIsLoading(true);
    Promise.all([
      AdminService.updateUser(user.id, values).then((res) => {
        setUser(res.data);
        setIsEditing((isEditing) => !isEditing);
      }),
      ...Object.entries(changedRoles).map(([accountId, roleId]) => UserService.updateRole(user.id as string, accountId, roleId)),
    ] as Promise<any>[]).finally(() => {
      setSubmitting(false);
      resetFormRef.current && resetFormRef.current();
      setChangedRoles({});
      setIsLoading(false);
      getUser();
    });
  };

  const safeAction = (newStatus: string, onConfirm: () => void) => {
    setModal(
      <ModalContext.Provider value={{ open: true }}>
        <ConfirmationModal
          title={t('common:permissions.user.modals.status.title')}
          description={
            <Trans
              t={t}
              i18nKey="common:permissions.user.modals.status.description"
              values={{
                user: user?.firstName || t('common:permissions.user.modals.this-user'),
                oldStatus: t(`common:user-status.${user?.active ? (user.verified ? 'active' : 'pending') : 'blocked'}`),
                newStatus: t(`common:user-status.${newStatus}` as any),
              }}
              components={{ bold: <strong /> }}
            />
          }
          confirmText={t('common:permissions.user.modals.status.title')}
          onCancel={() => setModal(null)}
          onConfirm={() => {
            setModal(null);
            onConfirm();
          }}
        />
      </ModalContext.Provider>,
    );
  };

  const confirm = useCallback(
    (modalKey: 'reset-password' | 'reset-2fa' | 'remove-user' | 'deactivate-user', onConfirm: () => void) => {
      setModal(
        <ModalContext.Provider value={{ open: true }}>
          <ConfirmationModal
            title={t(`common:permissions.user.modals.${modalKey}.title`)}
            description={t(`common:permissions.user.modals.${modalKey}.description`)}
            confirmText={t(`common:permissions.user.modals.${modalKey}.confirm-text`)}
            onCancel={() => setModal(null)}
            onConfirm={() => {
              setModal(null);
              onConfirm();
            }}
          />
        </ModalContext.Provider>,
      );
    },
    [t],
  );

  const deactivate = (userId: string) => {
    safeAction('deactivated', () =>
      UserService.disableUser(userId).then(() => {
        toasts.addToast({ title: t('common:permissions.toasts.disabled'), type: ToastType.SUCCESS, expiresInMs: 5000 });
        getUser();
      }),
    );
  };

  const activate = (userId: string) => {
    safeAction('enabled', () =>
      UserService.enableUser(userId).then(() => {
        toasts.addToast({ title: t('common:permissions.toasts.enabled'), type: ToastType.SUCCESS, expiresInMs: 5000 });
        getUser();
      }),
    );
  };

  const resendInvite = (userId: string, accountId: string) => {
    UserService.resendInvite(userId, accountId).then(() => {
      toasts.addToast({ title: t('common:permissions.toasts.resent-invite'), type: ToastType.SUCCESS, expiresInMs: 5000 });
    });
  };

  const resetPassword = () => {
    if (user?.email) {
      confirm('reset-password', () =>
        AuthService.forgotPassword(user.email as string).then(() => {
          toasts.addToast({ title: t('common:permissions.toasts.reset-password'), type: ToastType.SUCCESS, expiresInMs: 5000 });
        }),
      );
    }
  };

  const reset2Fa = (userId: string) => {
    confirm('reset-2fa', () =>
      AdminService.reset2Fa(userId).then(() => {
        toasts.addToast({ title: t('common:permissions.toasts.reset-2fa'), type: ToastType.SUCCESS, expiresInMs: 5000 });
        getUser();
      }),
    );
  };

  const makeDefaultAccount = useCallback(
    (userId: string, accountId: string) => {
      AdminService.setDefaultAccount(userId, accountId).then((res) => {
        setUser(res.data);
        toasts.addToast({ title: t('common:permissions.toasts.default-set'), type: ToastType.SUCCESS, expiresInMs: 5000 });
      });
    },
    [t, toasts],
  );

  const removeFromAccount = useCallback(
    (userId: string, accountId: string) => {
      confirm('remove-user', () =>
        ClientService.removeUser(accountId, userId).then(() => {
          toasts.addToast({ title: t('common:permissions.toasts.removed-from-account'), type: ToastType.SUCCESS, expiresInMs: 5000 });
          getUser();
        }),
      );
    },
    [confirm, getUser, t, toasts],
  );

  const accountStatusContext = () => {
    if (!user) {
      return [];
    }

    const actions: ContextMenuItem[] = [];
    if (user.active || !user.verified) {
      actions.push({ title: t('common:permissions.context-menu.disable'), onClick: () => deactivate(user.id as string) });
      if (user.defaultAccountId && !user.verified) {
        actions.push({
          title: t('common:permissions.context-menu.resend-invite'),
          onClick: () => resendInvite(user.id as string, user.defaultAccountId as string),
        });
      }
    } else {
      actions.push({ title: t('common:permissions.context-menu.enable'), onClick: () => activate(user.id || '') });
    }

    return actions;
  };

  const linkedAccountContext = useCallback(
    (userAccount: Client) => {
      if (!user || !userAccount) {
        return [];
      }
      const actions: ContextMenuItem[] = [];
      if (userAccount.id !== user.defaultAccountId && Object.keys(user.roles).length > 1) {
        actions.push({
          title: t('common:permissions.context-menu.set-default-account'),
          onClick: () => makeDefaultAccount(user.id as string, userAccount.id),
        });
        actions.push({
          title: t('common:permissions.context-menu.remove'),
          onClick: () => removeFromAccount(user.id as string, userAccount.id),
        });
      }

      return actions;
    },
    [makeDefaultAccount, removeFromAccount, t, user],
  );

  const resetPasswordContext = () => {
    if (!user) {
      return [];
    }
    const actions: ContextMenuItem[] = [];
    actions.push({
      title: t('common:permissions.context-menu.reset'),
      onClick: () => resetPassword(),
    });

    return actions;
  };

  const reset2FaContext = () => {
    if (!user) {
      return [];
    }
    const actions: ContextMenuItem[] = [];
    actions.push({
      title: t('common:permissions.context-menu.reset'),
      onClick: () => reset2Fa(user.id as string),
    });

    return actions;
  };

  const selectedUserName = user?.firstName || user?.lastName ? `${user?.firstName || ''} ${user?.lastName || ''}` : '-';

  useEffect(() => {
    setTopNavheading(selectedUserName);
    return () => {
      setTopNavheading('');
    };
  }, [selectedUserName, setTopNavheading]);

  const roleOptions = useMemo(() => {
    const result: Option<string, string>[] = [];
    for (const [roleKey, rank] of Object.entries(RolesHierarchy)) {
      if (rank < 999) {
        result.push({
          id: roleKey,
          value: rank.toString(),
          text: t(`common:roles.${roleKey}`),
        });
      }
    }

    return result;
  }, [t]);

  const changeAccountRole = useCallback((accountId: string, roleId: (typeof Roles)[keyof typeof Roles]) => {
    setChangedRoles((prev) => ({ ...prev, [accountId]: roleId }));
  }, []);

  return (
    <>
      {modal}
      {!user || isLoading ? (
        <PageLoader loading />
      ) : (
        <div className="bg-background-1 flex min-h-full flex-col pb-8">
          <div className="flex items-center justify-between">
            <div className="flex h-full flex-col justify-between pt-2">
              <TopNavPortal>
                <StaticBreadCrumb
                  breadCrumbs={[{ name: t('organisation:permissions.heading'), path: `/admin/iam/users` }]}
                  currentStepName={selectedUserName}
                />
              </TopNavPortal>
            </div>
          </div>
          <Formik
            enableReinitialize
            validateOnChange={isEditing}
            validationSchema={schemas.user.maintainUser}
            onSubmit={saveUser}
            initialValues={initialValues}
          >
            {(props) => {
              const { handleSubmit, resetForm, values, handleChange, handleBlur, errors, dirty, isValid, isInitialValid, isSubmitting } = props;
              resetFormRef.current = resetForm;
              const disabled = !dirty || !(isValid || isInitialValid) || isSubmitting;
              const handleSaveProfile = () => {
                handleSubmit();
              };
              return (
                <div className="p-4">
                  <div className="flex items-center gap-4 py-4">
                    <div className="flex w-full justify-between">
                      <Heading size={HeadingSize.H3}>Details</Heading>
                      <div className="flex items-start gap-4">
                        {!isEditing && (
                          <Button
                            data-cy="edit-user"
                            type={ButtonType.PRIMARY}
                            title={t('organisation:details.buttons.edit')}
                            onClick={() => setIsEditing((prev) => !prev)}
                          >
                            {t('organisation:details.buttons.edit')}
                          </Button>
                        )}
                        {isEditing && (
                          <Button
                            data-cy="save-user"
                            title={t('organisation:details.buttons.save')}
                            onClick={handleSaveProfile}
                            disabled={disabled && Object.entries(changedRoles).length === 0}
                            type={ButtonType.PRIMARY}
                          >
                            {t('organisation:details.buttons.save')}
                          </Button>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <PermissionUserDetailItem
                      title={t('common:permissions.user.sections.status')}
                      content={
                        <div className="-mr-8">
                          {user.active ? (
                            user.verified ? (
                              <StatusTag data-cy={`user-status-${user.id}`} statusType={StatusVariant.GREEN} text={t('common:user-status.active')} />
                            ) : (
                              <StatusTag
                                data-cy={`user-status-${user.id}`}
                                statusType={StatusVariant.ORANGE}
                                text={t('common:user-status.pending')}
                              />
                            )
                          ) : (
                            <StatusTag data-cy={`user-status-${user.id}`} statusType={StatusVariant.GRAY} text={t('common:user-status.blocked')} />
                          )}
                        </div>
                      }
                      action={<div className="ml-4">{<ContextMenu data-cy={`user-contect-menu-${user.id}`} items={accountStatusContext()} />}</div>}
                    />
                    <PermissionUserDetailItem title={t('common:permissions.user.sections.email-verified')} content={user.verified ? 'Yes' : 'No'} />
                    <PermissionUserDetailItem
                      title={t('common:permissions.user.sections.email')}
                      content={
                        <>
                          {!isEditing && values.emailAddress}
                          {isEditing && (
                            <Input
                              data-cy="emailAddress"
                              name="emailAddress"
                              placeholder={t('user-settings:details.email')}
                              value={values.emailAddress}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={errors.emailAddress}
                            />
                          )}
                        </>
                      }
                    />
                    <PermissionUserDetailItem
                      title={t('common:permissions.user.sections.firstname')}
                      content={
                        <>
                          {!isEditing && values.firstName}
                          {isEditing && (
                            <Input
                              data-cy="firstname"
                              name="firstName"
                              placeholder={t('user-settings:details.firstname')}
                              value={values.firstName}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={errors.firstName}
                            />
                          )}
                        </>
                      }
                    />
                    <PermissionUserDetailItem
                      title={t('common:permissions.user.sections.lastname')}
                      content={
                        <>
                          {!isEditing && values.lastName}
                          {isEditing && (
                            <Input
                              data-cy="lastname"
                              name="lastName"
                              placeholder={t('user-settings:details.lastname')}
                              value={values.lastName}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={errors.lastName}
                            />
                          )}
                        </>
                      }
                    />
                    <PermissionUserDetailItem
                      title={t('common:permissions.user.sections.job-title')}
                      content={
                        <>
                          {!isEditing && values.jobTitle}
                          {isEditing && (
                            <Input
                              data-cy="jobTitle"
                              name="jobTitle"
                              placeholder={t('organisation:details.inputs.job-title')}
                              value={values.jobTitle}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={errors.jobTitle}
                            />
                          )}
                        </>
                      }
                    />
                    <PermissionUserDetailItem
                      title={t('common:permissions.user.sections.contact-number')}
                      content={
                        <>
                          {!isEditing && values.phoneNumber}
                          {isEditing && (
                            <Input
                              data-cy="contact-number"
                              name="phoneNumber"
                              placeholder={t('organisation:details.inputs.contact')}
                              value={values.phoneNumber}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={errors.phoneNumber}
                            />
                          )}
                        </>
                      }
                    />
                    <PermissionUserDetailItem
                      title={t('common:permissions.user.sections.language')}
                      content={
                        <>
                          {!isEditing && languageOptions.find((x) => x.id === values.languageCode)?.text}
                          {isEditing && (
                            <Field id="languageCode" name="languageCode" type="string">
                              {({ field: { value }, form: { setFieldValue } }: FieldProps) => (
                                <DropdownSelect
                                  data-cy="pref-language"
                                  disabled={!isEditing}
                                  onChange={(data) => {
                                    setFieldValue('languageCode', data.value as string);
                                  }}
                                  options={languageOptions}
                                  value={languageOptions.find((x) => x.id === value)}
                                />
                              )}
                            </Field>
                          )}
                        </>
                      }
                    />
                    <div className="text-dpm-16 py-4 font-medium">Role Mapping</div>
                    <PermissionUserDetailItem
                      title={t('common:permissions.user.sections.architect')}
                      content={
                        <Checkbox
                          data-cy={`architect-checkbox-${user.id}`}
                          value={hasArchitectRole}
                          labelBeforeCheckbox
                          onChange={() => showArchitectModal()}
                        />
                      }
                    />
                    <div className="text-dpm-16 py-4 font-medium">Credentials</div>
                    <PermissionUserDetailItem
                      title={t('common:permissions.user.sections.is-password-set')}
                      content={
                        <div className="-mr-16">
                          {user.isPasswordSet && (
                            <StatusTag
                              data-cy={`user-password-set-${user.id}`}
                              statusType={StatusVariant.GREEN}
                              text={t('common:user-status.enabled')}
                            />
                          )}
                          {!user.isPasswordSet && (
                            <StatusTag data-cy={`user-status-${user.id}`} statusType={StatusVariant.ORANGE} text={t('common:user-status.pending')} />
                          )}
                        </div>
                      }
                      action={
                        <div className="ml-4 w-6">{<ContextMenu data-cy={`reset-password-menu-${user.id}`} items={resetPasswordContext()} />}</div>
                      }
                    />
                    <PermissionUserDetailItem
                      title={t('common:permissions.user.sections.2fa-enabled')}
                      content={
                        <div className="-mr-16">
                          {user.isTwoFactorAuthenticationSet && (
                            <StatusTag
                              data-cy={`user-password-set-${user.id}`}
                              statusType={StatusVariant.GREEN}
                              text={t('common:user-status.enabled')}
                            />
                          )}
                          {!user.isTwoFactorAuthenticationSet && (
                            <StatusTag
                              data-cy={`user-password-set-${user.id}`}
                              statusType={StatusVariant.ORANGE}
                              text={t('common:user-status.pending')}
                            />
                          )}
                        </div>
                      }
                      action={<div className="ml-4 w-6">{<ContextMenu data-cy={`reset-2fa-menu-${user.id}`} items={reset2FaContext()} />}</div>}
                    />
                    <div className="text-dpm-16 py-4 font-medium">Organisations</div>
                    {Object.entries(user.roles).map(([accountId, accountRoles]) => {
                      const account = accounts.find((x) => x.id === accountId);
                      if (!account) return null;

                      const contextMenu = linkedAccountContext(account);
                      const accountRole: (typeof Roles)[keyof typeof Roles] = changedRoles[account.id] || getHighestRole(accountRoles);
                      return (
                        <PermissionUserDetailItem
                          key={account.id}
                          title={
                            <div className="flex justify-start gap-2">
                              {account.name}{' '}
                              {(account.id === user.defaultAccountId || Object.keys(user.roles).length === 1) && (
                                <StatusTag data-cy={`main-account-${account.id}`} statusType={StatusVariant.ORANGE} text="Main" />
                              )}
                            </div>
                          }
                          content={
                            <div
                              className={`flex items-center justify-between gap-2 ${
                                !contextMenu.length && Object.keys(user.roles).length > 1 && 'mr-11'
                              }`}
                            >
                              {isEditing ? (
                                <DropdownSelect
                                  options={roleOptions}
                                  value={{
                                    id: accountRole,
                                    text: t(`common:roles.${accountRole}`),
                                    value: '',
                                  }}
                                  onChange={(o) => changeAccountRole(account.id, o.id as (typeof Roles)[keyof typeof Roles])}
                                />
                              ) : (
                                <span>{t(`common:roles.${accountRole}`)}</span>
                              )}
                            </div>
                          }
                          action={
                            <>
                              {!!contextMenu.length && (
                                <div className="ml-4">{<ContextMenu data-cy={`account-context-menu-${account.id}`} items={contextMenu} />}</div>
                              )}
                            </>
                          }
                        />
                      );
                    })}
                  </div>
                </div>
              );
            }}
          </Formik>
        </div>
      )}
    </>
  );
};

export default UserDetail;
